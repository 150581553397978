import { accountPageConstants } from "./action-types";
import { accountDataApi } from "../utils/api";
import { message } from "antd";
import { capitalizeStr } from "../../Common/utils/extraFunctions";
import axios from "axios";
import { logoutAndInWithBroadcast } from "../../App/components/Broadcast";
import { appStatusChange } from "../../App/state/actions";
/**
 * All Actions for Listing Page
 */

/**
 * To update Navbar title action
 * @param {string}
 */

// const listingError = (error) => {
//   return {
//     type: listingPageConstants.LISTING_PAGE_FAILURE,
//     listingError: error,
//     listLoading: true,
//   };
// };

// const listingSuccess = (response) => {
//   return {
//     type: listingPageConstants.LISTING_PAGE_SUCCESS,
//     listingData: response.data.info,
//     listLoading: false,
//   };
// };

// const getListingData = () => {
//   return (dispatch, getState) => {
//     // const token = getState().authentication.token;
//     const token = true;
//     listingDataApi
//       .listingPageLoadRequest(token)
//       .then((response) => {
//         dispatch(listingSuccess(response));
//       })
//       .catch((error) => {
//         dispatch(listingError(error));
//       });
//   };
// };

const getFranchiseLoading = (error) => {
  return {
    type: accountPageConstants.GET_FRANCHISE_DETAILS_LOADING,
    getFranchiseLoading:true
  };
};
// getting all the License
const getFranchiseError = (error) => {
  return {
    type: accountPageConstants.GET_FRANCHISE_DETAILS_FAILURE,
    getLicenseError: error,
  };
};

const getFranchiseSuccess = (response) => {
  return {
    type: accountPageConstants.GET_FRANCHISE_DETAILS_SUCCESS,
    getFranchiseData: response.data.info,
    getFranchiseLoading:false
  };
};

const getAllFranchise = (query) => (dispatch) => {

  dispatch(getFranchiseLoading());
  accountDataApi
    .getFranchiseRequest(query)
    .then((response) => {

      dispatch(getFranchiseSuccess(response));
    })
    .catch((error) => {
      dispatch(getFranchiseError(error));
      message.error(error?.response?.data?.message || "We encountered an issue while getting firms data. Please try again later!")
    });
};

const getBrokrageLoading = () => {
  return {
    type: accountPageConstants.GET_BROKRAGE_DETAILS_LOADING,
    getBrokrageLoading:true
  };
};
const getBrokrageSuccess = (response) => {
  return {
    type: accountPageConstants.GET_BROKRAGE_DETAILS_SUCCESS,
    getBrokrageData: response.data.info,
    getBrokrageLoading:false
  };
};
const getBrokrageError = (error) => {
  return {
    type: accountPageConstants.GET_BROKRAGE_DETAILS_SUCCESS,
    getBrokrageError: error,
  };
};
const getBrokrage = (query) => (dispatch) => {

  dispatch(getBrokrageLoading());
  return accountDataApi
    .getFranchiseRequest(query)
    .then((response) => {
     dispatch(getBrokrageSuccess(response));
    })
    .catch((error) => {
      dispatch(getBrokrageError(error));
    });
};



const getOfficeLoading = () => {
  return {
    type: accountPageConstants.GET_OFFICE_DETAILS_LOADING,
    getOfficeLoading:true
  };
};
const getOfficeSuccess = (response) => {
  return {
    type: accountPageConstants.GET_OFFICE_DETAILS_SUCCESS,
    getOfficeData: response.data.info,
    getOfficeLoading:false
  };
};
const getOfficeError = (error) => {
  return {
    type: accountPageConstants.GET_OFFICE_DETAILS_FAILURE,
    getOfficeError: error,
  };
};
const getOffice = (query) => (dispatch) => {
  dispatch(getOfficeLoading());
  return accountDataApi
    .getFranchiseRequest(query)
    .then((response) => {

      dispatch(getOfficeSuccess(response));
    })
    .catch((error) => {
      dispatch(getOfficeError(error));
    });
};


const updateUserProfileError = (error) => {
  return {
    type: accountPageConstants.USER_PROFILE_FAILURE,
    userProfileError: error,
    userProfileLoading: true,
  };
};

const updateUserProfileSuccess = (response) => {
  return {
    type: accountPageConstants.USER_PROFILE_SUCCESS,
    userProfileData: response.data.info,
    userProfileLoading: false,
  };
};

const updateUserProfile = (updateData) => {
  return (dispatch, getState) => {
    accountDataApi
      .updateUserProfileRequest(updateData)
      .then((response) => {
        message.success("Updated Successfully!");
        dispatch(getUserDetails());
        // dispatch(getUserSavedNotes(userSection));
        dispatch(updateUserProfileSuccess(response));
      })
      .catch((error) => {
        dispatch(updateUserProfileError(error));
      });
  };
};

// Reset account store
const resetAccountData = () => ({
  type: accountPageConstants.RESET_ACCOUNT_PAGE,
});

// Reset update password
const updatePasswordCleanUp = () => ({
  type: accountPageConstants.RESET_UPDATE_PASSWORD,
});

// Upload Image
const uploadImageError = (error) => {
  return {
    type: accountPageConstants.UPLOAD_IMAGE_FAILURE,
    uploadImageError: error,
    uploadImageLoading: false,
  };
};

const uploadImageSuccess = (response) => {
  return {
    type: accountPageConstants.UPLOAD_IMAGE_SUCCESS,
    uploadImageData: response.data.info.fileData,
    uploadImageLoading: false,
  };
};

const uploadImageforProfile = (file) => {
  return (dispatch, getState) => {
    accountDataApi
      .uploadImageforProfileRequest(file)
      .then((response) => {
        dispatch(uploadImageSuccess(response));
      })
      .catch((error) => {
        dispatch(uploadImageError(error));
      });
  };
};

// Get User details
const getUserDetailsLoading = () => {
  return {
    type: accountPageConstants.GET_USER_DETAILS_LOADING,
  };
};
const getUserDetailsError = (error) => {
  return {
    type: accountPageConstants.GET_USER_DETAILS_FAILURE,
    getUserDetailsError: error,
  };
};

const getUserDetailsSuccess = (userData={}) => {
  const userFullName =
    (userData.firstName ? capitalizeStr(userData.firstName) : "") +
    " " +
    (userData.middleName && userData.middleName !== "null"
      ? capitalizeStr(userData.middleName)
      : "") +
    " " +
    (userData.lastName ? capitalizeStr(userData.lastName) : "");

  return {
    type: accountPageConstants.GET_USER_DETAILS_SUCCESS,
    getUserDetailsData: userData,
    roleAccessed: userData.roleAccessed,
    userId: userData._id,
    userName: userFullName,
    subscriptionExists: userData.subscriptionExists || false,
    subscriptionRequired: userData.subscriptionRequired || false
  };
};

const getUserDetails = () => {
  return (dispatch) => {
    dispatch(getUserDetailsLoading());
    accountDataApi
      .getUserDetailsRequest()
      .then((response) => {
        let userData = response.data?.info
        dispatch(getUserDetailsSuccess(userData));
      })
      .catch((error) => {
        if(error?.response?.data?.attributes?.error === "Expired token"){
          localStorage.clear();
          message.error("Your session has expired. Please log in again to continue.");
        }else{
          message.error(error?.response?.data?.message || "We encountered an issue while getting user details. Please try again later!");
        }
        dispatch(getUserDetailsError(error));
      });
  };
};

const getClientDetails = () => {
  return (dispatch) => {
    dispatch(getUserDetailsLoading());
    accountDataApi
      .getClientDetailsRequest()
      .then((response) => {
        dispatch(getUserDetailsSuccess(response.data.info));
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue while getting client details. Please try again later!");
        dispatch(getUserDetailsError(error));
      });
  };
};

//update password
const updatePasswordError = (error) => {
  return {
    type: accountPageConstants.UPDATE_USER_PASSWORD_FAILURE,
    updatePasswordError: error,
    updatePasswordLoading: false,
  };
};

const updatePasswordSuccess = (response) => {
  return {
    type: accountPageConstants.UPDATE_USER_PASSWORD_SUCCESS,
    updatePasswordData: response.data.info,
    updatePasswordLoading: false,
  };
};

const updatePassword = (personData) => {
  return (dispatch, getState) => {
    accountDataApi
      .updatePasswordRequest(personData)
      .then((response) => {
        dispatch(updatePasswordSuccess(response));
      })
      .catch((error) => {
        dispatch(updatePasswordError(error));
      });
  };
};

//Edit name Data
const updateNameError = (error) => {
  return {
    type: accountPageConstants.EDIT_NAME_FAILURE,
    updateNameError: error,
    updateNameLoading: false,
  };
};

const updateNameSuccess = (response) => {
  return {
    type: accountPageConstants.EDIT_NAME_SUCCESS,
    updateNameDetails: response.data.info,
    updateNameLoading: false,
  };
};

const updateName = (data, id, updateLocation) => {
  return (dispatch, getState) => {
    accountDataApi
      .updateNameRequest(data, id, updateLocation)
      .then((response) => {
        dispatch(updateNameSuccess(response));
        dispatch(getUserDetails(id));
      })
      .catch((error) => {
        dispatch(updateNameError(error));
      });
  };
};

//update Address
const updateAddressError = (error) => {
  return {
    type: accountPageConstants.UPDATE_ADDRESS_FAILURE,
    updateAddressError: error,
    updateAddressLoading: false,
  };
};

const updateAddressSuccess = (response) => {
  return {
    type: accountPageConstants.UPDATE_ADDRESS_SUCCESS,
    updateAddressDetails: response.data.info,
    updateAddressLoading: false,
  };
};

const updateAddress = (values, data, personId) => {
  return (dispatch, getState) => {
    accountDataApi
      .updateAddressRequest(values, data, personId)
      .then((response) => {
        dispatch(updateAddressSuccess(response));
        dispatch(getUserDetails(personId));
      })
      .catch((error) => {
        dispatch(updateAddressError(error));
      });
  };
};

// Reset name details
const reSetName = () => ({
  type: accountPageConstants.CLIENT_NAME_RESET,
});

// Reset Address details
const reSetAddress = () => ({
  type: accountPageConstants.CLIENT_ADDRESS_RESET,
});

// create a License
const createLicenceLoading = (error) => {
  return {
    type: accountPageConstants.CREATE_LICENCE_LOADING,
  };
};
const createLicenceError = (error) => {
  return {
    type: accountPageConstants.CREATE_LICENCE_FAILURE,
    createLicenseError: error,
  };
};

const createLicenceSuccess = (response) => {
  return {
    type: accountPageConstants.CREATE_LICENCE_SUCCESS,
    createLicenseData: response.data.info,
  };
};

  const createLicense =
    ({ payload, delegateOwnerId, getUserDetailsData }) =>
    (dispatch) => {
      dispatch(createLicenceLoading());

      const setInvalidLicenseStatus = (reason) => {
        payload.licenseStatus = "INVALID";
        payload.notes = reason || "";
        createLicenceRequest();
      };

      const createLicenceRequest = () => {
        accountDataApi
          .createLicenceRequest({ payload, delegateOwnerId })
          .then((response) => {
            dispatch(createLicenceSuccess(response));
            message.success("License ID Created Successfully!", 2);
          })
          .catch((error) => {
            message.error(error?.response?.data?.message || "ID Type Already Created!");
            dispatch(createLicenceError(error));
          });
      };

      if (payload?.idType === "COLORADO STATE LICENSE") {
        const normalizedId = payload.id?.trim()?.toUpperCase();
        const alphaPart = normalizedId?.replace(/[^A-Z]+/g, "");
        const numericPart = normalizedId?.replace(/\D+/g, "");
        const handleLicenseCheck = (res) => {
          if (res?.data?.length > 0) {
            const licensePrefixFromRes = res?.data[0]?.licenseprefix;

            if (alphaPart) {
              if (alphaPart !== licensePrefixFromRes) {
                payload.id = licensePrefixFromRes + numericPart;
              } else {
                payload.id = alphaPart + numericPart;
              }
            } else if (licensePrefixFromRes) {
              payload.id = licensePrefixFromRes + numericPart;
            }
            payload.expiryDate = res?.data[0]?.licenseexpirationdate ? res?.data[0]?.licenseexpirationdate : null;
            payload.licenseStatus = res?.data?.[0]?.licensestatus? res.data[0].licensestatus.toUpperCase() === "INACTIVE" ? "EXPIRED" : res.data[0].licensestatus.toUpperCase() === "ACTIVE" ? "ACTIVE" : "INVALID": "INVALID";
            createLicenceRequest();
          } else {
            payload.id = alphaPart + numericPart
            setInvalidLicenseStatus("License ID is not valid.");
          }
        };

        // const licenseApiUrl = alphaPart && numericPart ? `https://data.colorado.gov/resource/4zse-6bnw.json?licenseprefix=${alphaPart}&licensenumber=${numericPart}` : `https://data.colorado.gov/resource/4zse-6bnw.json?licensenumber=${numericPart}`;
        let licenseApiUrl;
        if(numericPart){
           licenseApiUrl = `https://data.colorado.gov/resource/4zse-6bnw.json?licensenumber=${numericPart}`;
        }
        axios
          .get(licenseApiUrl)
          .then(handleLicenseCheck)
          .catch((err) => setInvalidLicenseStatus("Error validating license ID."));
      } else {
        createLicenceRequest();
      }
    };

const getLicenseLoading = (error) => {
  return {
    type: accountPageConstants.GET_LICENCE_LOADING,
  };
};
// getting all the License
const getLicenseError = (error) => {
  return {
    type: accountPageConstants.GET_LICENCE_FAILURE,
    getLicenseError: error,
  };
};

const getLicenseSuccess = (response) => {

  return {
    type: accountPageConstants.GET_LICENCE_SUCCESS,
    getLicenseData: response.data.info,
    licenseid:response.data.info.length>0?true:false
  };
};

const getAllLicense = ({source, unmounted, delegateOwnerId}) => (dispatch) => {
  dispatch(getLicenseLoading());
  accountDataApi
    .getLicenseRequest({source, delegateOwnerId})
    .then((response) => {
      if(!unmounted.value){
        dispatch(getLicenseSuccess(response));
      }
    })
    .catch((error) => {
      if(!unmounted.value){
        dispatch(getLicenseError(error));
        message.error(error?.response?.data?.message || "We encountered an issue while getting license details. Please try again later!")
      }
    });
};

const updateLicenseSuccess = (response) => {
  return {
    type: accountPageConstants.UPDATE_LICENCE_SUCCESS,
    updateLicenseData: response.data.info,
  };
};

const updateLicense = ({ licenseMongoId, payload, getUserDetailsData }) => (dispatch) => {
  dispatch(createLicenceLoading());
  const setInvalidLicenseStatus = (reason) => {
    payload.licenseStatus = "INVALID";
    payload.notes = reason || "";
    updateLicenseRequest();
  };

  const updateLicenseRequest = () => {
    accountDataApi
      .updateLicenseRequest({ licenseMongoId, payload })
      .then((response) => {
        dispatch(updateLicenseSuccess(response));
        message.success("License ID Updated Successfully!", 2);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "Error updating license ID!");
        dispatch(createLicenceError(error));
      });
  };

  if (payload.idType === "COLORADO STATE LICENSE") {
    const normalizedId = payload.id?.trim()?.toUpperCase();
    const alphaPart = normalizedId?.replace(/[^A-Z]+/g, "");
    const numericPart = normalizedId?.replace(/\D+/g, "");
    const handleLicenseCheck = (res) => {
      if (res?.data?.length > 0) {
        const licensePrefixFromRes = res?.data[0]?.licenseprefix;

        if (alphaPart) {
          if (alphaPart !== licensePrefixFromRes) {
            payload.id = licensePrefixFromRes + numericPart;
          } else {
            payload.id = alphaPart + numericPart;
          }
        } else if (licensePrefixFromRes) {
          payload.id = licensePrefixFromRes + numericPart;
        }
        payload.expiryDate = res?.data[0]?.licenseexpirationdate ? res?.data[0]?.licenseexpirationdate : null;
        payload.licenseStatus = res?.data?.[0]?.licensestatus? res.data[0].licensestatus.toUpperCase() === "INACTIVE" ? "EXPIRED" : res.data[0].licensestatus.toUpperCase() === "ACTIVE" ? "ACTIVE" : "INVALID": "INVALID";
         updateLicenseRequest();
      } else {
        payload.id = alphaPart + numericPart
        payload.expiryDate = null
        setInvalidLicenseStatus("License ID is not valid.");
      }
    };
    // const licenseApiUrl = alphaPart && numericPart 
    //   ? `https://data.colorado.gov/resource/4zse-6bnw.json?licenseprefix=${alphaPart}&licensenumber=${numericPart}` 
    //   : `https://data.colorado.gov/resource/4zse-6bnw.json?licensenumber=${numericPart}`;
    let licenseApiUrl;
    if (numericPart) {
      licenseApiUrl = `https://data.colorado.gov/resource/4zse-6bnw.json?licensenumber=${numericPart}`;
    }
    axios
      .get(licenseApiUrl)
      .then(handleLicenseCheck)
      .catch((err) => setInvalidLicenseStatus("Error validating license ID."));
  } else {
    updateLicenseRequest();
  }
};


const deleteLicenseSuccess = (response) => {
  return {
    type: accountPageConstants.DELETE_LICENCE_SUCCESS,
  };
};

const deleteLicense = (licenseMongoId, message) => (dispatch) => {
  dispatch(createLicenceLoading());
  accountDataApi
    .deleteLicenseRequest(licenseMongoId)
    .then((response) => {
      dispatch(deleteLicenseSuccess(response));
      message.success("License deleted Successfully!", 2);
    })
    .catch((error) => {
      message.error(error.response.data.message);
      dispatch(createLicenceError(error));
    });
};

const getUserSavedNotesSuccess = (response) => {
  return {
    type: accountPageConstants.GET_USER_SAVED_NOTES_SUCCESS,
    payload: response.data.info.dashboardNotes,
    getUserDetailsLoading: false,
  };
};

const getUserSavedNotes = (personId) => {
  return (dispatch, getState) => {
    accountDataApi
      .getUserDetailsRequest(personId)
      .then((response) => {
        dispatch(getUserSavedNotesSuccess(response));
      })
      .catch((error) => {
        // dispatch(getUserDetailsError(error));
      });
  };
};

const loadAccessProfile = (response) => {
  return {
    type: accountPageConstants.GET_USER_ACCESS_LOADING,
  };
};

const errorAccessProfile = (response) => {
  return {
    type: accountPageConstants.GET_USER_ACCESS_ERROR,
  };
};

const dataAccessProfile = (response) => {
  return {
    type: accountPageConstants.GET_USER_ACCESS_DATA,
    accessData: response.data.info,
  };
};

const updateAccessProfile = (data, goToAccount) => {
  return (dispatch) => {
    dispatch(loadAccessProfile());
    accountDataApi
      .updateUserProfileRequest(data)
      .then((response) => {
        message.success("Updated Successfully!");
        dispatch(dataAccessProfile(response));
        if (goToAccount) {
          dispatch(loginAnotherAccount({ delegateOwnerId: data.delegateOwnerId}));
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue updating the access profile. Please try again later!");
        dispatch(errorAccessProfile());

      });
  };
};

const getWholeAppLoading = (value) => {
  return {
    type: accountPageConstants.WHOLE_APP_LOADING,
    wholeAppLoading: value,
  };
};

const getWholeAppError = (error) => {
  return {
    type: accountPageConstants.WHOLE_APP_ERROR,
  };
};

const loginAnotherAccount = (data) => {
  return (dispatch) => {
    dispatch(getWholeAppLoading(true));
    accountDataApi
      .loginToDifferentAccount(data)
      .then((response) => {
        localStorage.setItem("userAuth", response.data.info.accessToken);
        logoutAndInWithBroadcast();
        window.location.href = `/`;
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue accessing the account. Please try again later!");
        dispatch(getWholeAppError());
      });
  };
};

const logoutDelegate = (removeDelegate) => {
  return (dispatch) => {
    dispatch(getWholeAppLoading(true));
    accountDataApi
      .updateUserProfileRequest({ removeDelegate })
      .then((response) => {
        message.success(response?.data?.info?.message || "Success!");
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      })
      .catch((error)=>{
        message.error(error?.response?.data?.message || "We encountered an issue while logging out delegate. Please try again later!");
        dispatch(getWholeAppError())
      })
  }
}

const logDetailLoading = () => {
  return {
    type: accountPageConstants.GET_LOG_LOADING
  };
}

const logDetailError = () => {
  return {
    type: accountPageConstants.GET_LOG_ERROR
  }
}

const logDetailData = (response) => {
  return {
    type: accountPageConstants.GET_LOG_DATA,
    logsData: response.data.info
  }
}

const getLogDetails = () => {
  return (dispatch) => {
    dispatch(logDetailLoading());
    accountDataApi.getLogDetailsRequest()
      .then((response)=>{
        dispatch(logDetailData(response))
      })
      .catch((error)=>{
        message.error(error?.response?.data?.message || "We encountered an issue while getting log details. Please try again later!");
        dispatch(logDetailError())
      })
  }
}

// gET SAFE - T- dRIVE
const safeTDriveLoading = () => {
  return {
    type: accountPageConstants.GET_SAFE_T_DRIVE_LOADING
  };
}

const safeTDriveError = () => {
  return {
    type: accountPageConstants.GET_SAFE_T_DRIVE_ERROR
  }
}

const getSafeTDriveData = (response) => {
  return {
    type: accountPageConstants.GET_SAFE_T_DRIVE_SUCCESS,
    safeTDriveData: response.data.info?.[0]?.data,
    safeTDriveTotalData: response.data.info?.[0]?.totalItems?.[0]?.total
  }
}

const getsafeTDriveDetails = (page,limit,byKey,byAccDce) => {
  return (dispatch) => {
    dispatch(safeTDriveLoading());
    accountDataApi.getSafeTDriveRequest(page,limit,byKey,byAccDce)
      .then((response)=>{
        dispatch(getSafeTDriveData(response))
      })
      .catch((error)=>{
        message.error(error?.response?.data?.message || "We encountered an issue while getting RE-Sure drive details. Please try again later!");
        dispatch(safeTDriveError())
      })
  }
}


const updateSafeTDriveDetails = ({updatedValue,id}) => {
  return (dispatch) => {
    dispatch(safeTDriveLoading());
    accountDataApi.updateSafeTDriveDetailsRequest({updatedValue,id})
      .then((response)=>{
        message.success(response?.data?.info?.message || "Updated Successfully!");
        dispatch(getsafeTDriveDetails())
      })
      .catch((error)=>{
        message.error(error?.response?.data?.message || "We encountered an issue while updating RE-Sure drive details. Please try again later!");
        dispatch(safeTDriveError())
      })
  }
}
// Create safe-t-drive
const createSafeTDriveData = (response) => {
  return {
    type: accountPageConstants.CREATE_SAFE_T_DRIVE_SUCCESS,
    createdSafeTDriveData: response.data.info
  }
}

const createsafeTDriveDetails = (payload) => {
  return (dispatch) => {
    dispatch(safeTDriveLoading());
    accountDataApi.createSafeTDriveRequest(payload)
      .then((response)=>{
        dispatch(getsafeTDriveDetails())
        dispatch(createSafeTDriveData(response));
      })
      .catch((error)=>{
        message.error("We encountered an issue while creating RE-Sure drive details. Please try again later!" || error?.response?.data?.message);
        dispatch(safeTDriveError())
      })
  }
}

// Update one from many CBSR safe-t-drive
const createOneCBSRSafeTDriveData = (response) => {
  return {
    type: accountPageConstants.UPDATE_SELECTED_SAFE_T_DRIVE_SUCCESS,
    createdOneCBSRSafeTDriveData: response.data.info
  }
}

const createOneCBSRsafeTDriveDetails = (payload) => {
  return (dispatch) => {
    dispatch(safeTDriveLoading());
    accountDataApi.createOneCBSRSafeTDriveRequest(payload)
      .then((response)=>{
        dispatch(createOneCBSRSafeTDriveData(response))
      })
      .catch((error)=>{
        message.error(error?.response?.data?.message || "We encountered an issue while creating cbsr RE-Sure drive details. Please try again later!");
        dispatch(safeTDriveError())
      })
  }
}
// Search safe-t-t
const searchSafeTDriveData = (response) => {
  return {
    type: accountPageConstants.SEARCH_SAFE_T_DRIVE_SUCCESS,
    searchedResultSafeTDriveData: response.data.info
  }
}

const searchsafeTDriveDetails = (payload,byKey,byAccDce) => {
  return (dispatch) => {
    dispatch(safeTDriveLoading());
    accountDataApi.searchSafeTDriveRequest(payload,byKey,byAccDce)
      .then((response)=>{
        dispatch(searchSafeTDriveData(response));
        if(response?.data?.info?.length===0){
          message.error("No data found !")
        }
      })
      .catch((error)=>{
        message.error(error?.response?.data?.message || "We encountered an issue while searching through RE-Sure drive. Please try again later!");
        dispatch(safeTDriveError())
      })
  }
}

// get aws buffer Data
const getSafeTDrivebufferDataSuccess = (response)=>{
  return {
    type: accountPageConstants.GET_AWS_SAFE_T_DRIVE_SUCCESS,
    AwsBufferDataSafeTDrive: response.data.info.data
  }
}
const getSafeTDrivebufferData = (payload) => {
  return (dispatch) => {
    // dispatch(safeTDriveLoading());
    accountDataApi.getSafeTDrivebufferDataRequest(payload)
      .then((response)=>{
        dispatch(getSafeTDrivebufferDataSuccess(response));
      })
      .catch((error)=>{
        message.error(error?.response?.data?.message || "We encountered an issue while getting RE-Sure drive buffer data. Please try again later!");
        // dispatch(safeTDriveError())
      })
  }
}

const deleteDriveFolder = (id) => {
  return (dispatch) => {
    dispatch(safeTDriveLoading());
    accountDataApi.deleteDriveFolderRequest(id)
    .then((response) => {
      message.success(response?.data?.info?.message || "Deleted Successfully!");
      dispatch(getsafeTDriveDetails())
    })
    .catch((error) => {
      message.error(error?.response?.data?.message || "We encountered an issue while deleting RE-Sure drive data. Please try again later!");
      dispatch(safeTDriveError())
    })
  }
}

// ---------remove data from reducer
const removeSafeTDrivebufferDataSuccuess = (page) => {
  return {
    type: "REMOVE_AWS_DATA_SAFE_T_DRIVE_SUCCESS",
  }
}
const removeSafeTDrivebufferDataFromRedu = (page) => {
  return (dispatch) => {
    dispatch(removeSafeTDrivebufferDataSuccuess(page))
  }
}
// -------
const handlePaginantionInZipUploadScuess = (page) => {
  return {
    type: "HANDLE_PAGINATION_IN_ZIP",
    pageNumberInZip:page
  }
}
const handlePaginantionInZipUpload = (page) => {
  return (dispatch) => {
    dispatch(handlePaginantionInZipUploadScuess(page))
  }
}
//
const updateSearchedTextSuccuess = (text) => {
  return {
    type: accountPageConstants.UPDATE_SEARCHED_TEXT_SUCCESS,
    searchedText:text
  }
}
const updateSearchedText = (text) => {
  return (dispatch) => {
    dispatch(updateSearchedTextSuccuess(text))
  }
}
// Update date
const updateDateSearchInZipSuccuess = (date) => {
  return {
    type: accountPageConstants.UPDATE_DATE_SEARCH_SUCCESS,
    dateSearch:date
  }
}
const updateDateSearchInZip = (date) => {
  return (dispatch) => {
    dispatch(updateDateSearchInZipSuccuess(date))
  }
}
// Update SortBy
const updateSortByInZipSuccuess = (sortVal) => {
  return {
    type: accountPageConstants.UPDATE_SORTBY_SUCCESS,
    sortBy:sortVal
  }
}
const updateSortByInZip = (sortVal) => {
  return (dispatch) => {
    dispatch(updateSortByInZipSuccuess(sortVal))
  }
}

//Get deligates
const getDelegateListLoading = () => {
    return {
      type: accountPageConstants.GET_DELEGATE_LIST_LOADING,
    };
  };
  const getDelegateListError = (error) => {
    return {
      type: accountPageConstants.GET_DELEGATE_LIST_ERROR,
      getDelegateListError: error,
    };
  };

  const getDelegateListSuccess = (Data) => {
    return {
      type: accountPageConstants.GET_DELEGATE_LIST_SUCCESS,
      getDelegateListData: Data,
    };
  };

  const getDelegateList = (Id,source) => {
    return (dispatch) => {
      dispatch(getDelegateListLoading());
      accountDataApi
        .getDelegateListRequest(Id,source)
        .then((response) => {

          dispatch(getDelegateListSuccess(response.data.info));
        })
        .catch((error) => {
          message.error(error?.response?.data?.message || "We encountered an issue while getting delegate list. Please try again later!")
          dispatch(getDelegateListError(error));
        });
    };
  };

  const clearDelegateList = () => {
    return {
      type: accountPageConstants.CLEAR_DELEGATES_LIST,
    };
  };

  const updateDelegateSuccess = () => {
    return {
      type: accountPageConstants.UPDATE_DELEGATE_SUCCESS,
    };
  };

  const updatePersonCreated = (payload) => {
    const { delegateValue, ...payloadWithoutDelegate } = payload;
    return (dispatch, getState) => {
        dispatch(appStatusChange.loadOpacityLoader(true));
        accountDataApi
        .updatePerson(payloadWithoutDelegate)
        .then((response) => {
          dispatch(appStatusChange.loadOpacityLoader(false));
          message.success(response?.data?.info?.message || "Updated Successfully!");
          dispatch(updateDelegateSuccess());
        })
        .catch((err) => {
          dispatch(appStatusChange.loadOpacityLoader(false));
          message.error(err?.response?.data?.message || "");
        });
    };
  };

  const getPartialDataLoading = () => {
    return {
      type:accountPageConstants.GET_PARTIAL_DATA_LOADING,
    }
  }

  const getPartialDataError = (err) => {
    return {
      type:accountPageConstants.GET_PARTIAL_DATA_ERROR,
    }
  }

  const getPartialDataSuccess = (data) => {
    return {
      type:accountPageConstants.GET_PARTIAL_DATA_SUCCESS,
      delegatePartialData:data,
    }
  }
 // not in use
  const getPartialData = (id, delegateTo) => {
    return (dispatch, getState) => {
      dispatch(getPartialDataLoading())
      accountDataApi
      .getPartialDelegateProperties(id, delegateTo)
      .then((response) => {
        dispatch(getPartialDataSuccess(response.data.info))
      })
      .catch((err) => {
        dispatch(getPartialDataError(err))
        message.error(err?.response?.data?.message || "We encountered an issue while getting delegate properties. Please try again later!");
      });
  };
  }

  const getDelegateDataSuccess = (Data) => {
    return {
      type: accountPageConstants.GET_DELEGATE_DATA_SUCCESS,
      getDelegateData: Data,
    };
  };

  const getDelegateDataLoading = () => {
    return {
      type: accountPageConstants.GET_DELEGATE_DATA_LOADING,
    };
  };
  const getDelegateDataError = (error) => {
    return {
      type: accountPageConstants.GET_DELEGATE_DATA_ERROR,
      getDelegateDataError: error,
    };
  };

  const getDelegateData = (query,delegate) => {
    return (dispatch) => {
      dispatch(getDelegateDataLoading());
      accountDataApi
        .getDelegateDetailsRequest(query,delegate)
        .then((response) => {
          dispatch(getDelegateDataSuccess(response.data.info));
        })
        .catch((error) => {
          message.error(error?.response?.data?.message || "We encountered an issue while getting delegate data. Please try again later!")
          dispatch(getDelegateDataError(error));
        });
    };
  };

  const resetDelegateData = ()=>{
    return {
        type:accountPageConstants.RESET_DELEGATE_DATA,
    }
  }

  const uploadEmailSignSuccess = (data) => {
    message.success(data?.message)
    return {
      type: accountPageConstants.UPLOAD_EMAIL_SIGN_SUCCESS,
    };
  };

  const uploadEmailSignLoading = () => {
    return {
      type: accountPageConstants.UPLOAD_EMAIL_SIGN_LOADING,
    };
  };
  const uploadEmailSignError = (error) => {
    return {
      type: accountPageConstants.UPLOAD_EMAIL_SIGN_ERROR,
    };
  };

  const uploadEmailSignImg = ({imageUrl,footerText,title,isDefault}) => {
    let payload ={
        signatureImageUrl: imageUrl,
        ...(footerText ? {footerText: footerText}:{}),
        ...(title ? {title}:{}),
        // delegateOwnerId: ,
        isDefault
    }
    return (dispatch) => {
      dispatch(uploadEmailSignLoading());
      accountDataApi
        .uploademailSignRequest(payload)
        .then((response) => {
          dispatch(uploadEmailSignSuccess(response.data.info));
        })
        .catch((error) => {
          message.error(error?.response?.data?.message)
          dispatch(uploadEmailSignError(error));
        });
    };
  };

  const updateEmailSignImg = ({imageUrl,footerText,title,isDefault,id}) => {
    let payload ={
        title,
        isDefault,
        ...((imageUrl || imageUrl === "") ? {signatureImageUrl: imageUrl,}:{}),
        ...(footerText ? {footerText: footerText}:{}),
    }
    return (dispatch) => {
      dispatch(uploadEmailSignLoading());
      accountDataApi
        .updateEmailSignRequest(payload,id)
        .then((response) => {
          dispatch(uploadEmailSignSuccess(response.data.info));
        })
        .catch((error) => {
          message.error(error?.response?.data?.message)
          dispatch(uploadEmailSignError(error));
        });
    };
  };

//   in case when image url is not present
  const uploadImageForEmailSign = ({imgData,footerText,title,isDefault,ismodal,id})=>{
    return (dispatch) => {
        dispatch(uploadEmailSignLoading());
        accountDataApi.uploadImageforEmailSignRequest(imgData)
        .then((response) => {
            if(ismodal.openFor === 'CREATE'){
                dispatch(uploadEmailSignImg({imageUrl:response?.data?.info?.fileData?.fileUrl, footerText, title, isDefault}))
            }else{
                dispatch(updateEmailSignImg({imageUrl:response?.data?.info?.fileData?.fileUrl, footerText, title, isDefault, id}))
            }
        })
        .catch((error) => {
            dispatch(uploadEmailSignError());
        });
    }
  }

  const getEmasilSignSuccess = (data) => {
    return {
      type: accountPageConstants.GET_EMAIL_SIGN_DATA_SUCCESS,
      emailSignData:data
    };
  };

  const getEmasilSignLoading = () => {
    return {
      type: accountPageConstants.GET_EMAIL_SIGN_DATA_LOADING,
    };
  };
  const getEmasilSignError = (error) => {
    return {
      type: accountPageConstants.GET_EMAIL_SIGN_DATA_ERROR,
    };
  };

  const getEmailSignData=({unmounted, source})=>{
    return (dispatch)=> {
     dispatch(getEmasilSignLoading());
     accountDataApi.getEmailSignRequest({source})
     .then((response)=>{
        dispatch(getEmasilSignSuccess(response?.data?.info))
     })
     .catch((error)=>{
        if(!unmounted.value){
            dispatch(getEmasilSignError())
        }
     });
    }
  }


  const deleteEmilSignSuccess = (data) => {
    message.success(data?.message)
    return {
      type: accountPageConstants.DELETE_EMAIL_SIGN_SUCCESS,
    };
  };

  const deleteEmilSignLoading = () => {
    return {
      type: accountPageConstants.DELETE_EMAIL_SIGN_LOADING,
    };
  };
  const deleteEmilSignError = (error) => {
    return {
      type: accountPageConstants.DELETE_EMAIL_SIGN_ERROR,
    };
  };

  const deleteEmilSign = (id) => {
    return (dispatch) => {
      dispatch(deleteEmilSignLoading());
      accountDataApi
        .deleteEmailSignRequest(id)
        .then((response) => {
          dispatch(deleteEmilSignSuccess(response.data.info));
        })
        .catch((error) => {
          message.error(error?.response?.data?.message)
          dispatch(deleteEmilSignError(error));
        });
    };
  };

  const editBrokerageLoading = () => {
    return {
      type:accountPageConstants.EDIT_BROKERAGE_LOADING
    }
  }

  const editBrokerageError = () => {
    return {
      type:accountPageConstants.EDIT_BROKERAGE_ERROR
    }
  }

const editBrokerageSuccess = () => {
  return {
    type:accountPageConstants.EDIT_BROKERAGE_SUCCESS
  }
}
  const editBrokerage = (urlData,logoImage) => {
    return (dispatch) => {
      dispatch(editBrokerageLoading())
      accountDataApi.editBrokerage(urlData,logoImage)
      .then((response) => {
        dispatch(editBrokerageSuccess())
        dispatch(getUserDetails())
        message.success(response?.data?.info?.message || "Updated successfully")
      })
      .catch((error) => {
        dispatch(editBrokerageError(error))
        message.error(error?.response?.data?.message || "We encountered an issue while editing brokerage. Please try again later!")
      });
    };
  }


export const accountHandle = {
  getBrokrage,
  getWholeAppLoading,
  getOffice,
  getAllFranchise,
  uploadImageforProfile,
  resetAccountData,
  updateUserProfile,
  getUserDetails,
  getClientDetails,
  updatePassword,
  updatePasswordCleanUp,
  reSetName,
  reSetAddress,
  updateName,
  updateAddress,
  createLicense,
  getAllLicense,
  updateLicense,
  deleteLicense,
  getUserSavedNotes,
  updateAccessProfile,
  loginAnotherAccount,
  logoutDelegate,
  getLogDetails,
  getsafeTDriveDetails,
  createsafeTDriveDetails,
  createOneCBSRsafeTDriveDetails,
  searchsafeTDriveDetails,
  handlePaginantionInZipUpload,
  getSafeTDrivebufferData,
  removeSafeTDrivebufferDataFromRedu,
  updateSearchedText,
  updateDateSearchInZip,
  updateSortByInZip,
  getDelegateList,
  updatePersonCreated,
  getPartialData,
  clearDelegateList,
  getDelegateData,
  createLicenceError,
  resetDelegateData,
  uploadImageForEmailSign,
  getEmailSignData,
  deleteEmilSign,
  updateEmailSignImg,
  uploadEmailSignImg,
  editBrokerage,
  deleteDriveFolder,
  updateSafeTDriveDetails
};
