/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Spin, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  FolderOutlined,
  PlusCircleFilled,
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { MailOutlined } from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CreateDocs from "./CreateDocs";
import { SignableAndReadOnly } from "./SignableAndReadOnly";
import { PropertyHandlers } from "../state/actions";
import { DraggerComp } from "../../Common/components/DraggerComp";

import axios from "axios";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { checkIfDisabled, createOfferContractTypes, disabledTransactionText } from "../../Common/commondata/commonData";
import PropertyDocsTable from "./PropertyDocsTable";
import { getAgentsArrayFromIndividualTxnData } from "./helper";

const PropertyDocsModal = ({ isModalOpen, setIsModalOpen, role, section, item }) => {
  const delegate = (section === "DELEGATE");
  let { propertyDocs, propertDocsLoading, updatedPropertyDoc, uploadedPdfDocSuccess } = useSelector((state) => state.PropertyReducer,shallowEqual);
  const isSmallScreen = useMediaQuery({ maxWidth: 480 });
  const location = useLocation();
  const dispatch = useDispatch();
  const uploadFile = useRef("");
  // const agentDetails = (item?.isProperty || item?.isErts) ? (item?.currentSellerAgentId) : item?.currentBuyerAgentId;
  const agentDetails = (item?.currentAgentId);
  const {filterSort} = useSelector((state) => state.PropertyReducer);
  const [currentPropertyDocId, setCurrentPropertyDocId] = useState({
    clickedItem: "",
    source: "",
  });
  const { getIndividualTransactionData } = useSelector((state) => state.rtdTransactionLane);
  const [loading, setLoading] = useState(false);
  const [nestedModalOpen, setNestedModalOpen] = useState({
    isUploadDocsModal: false,
    isEmailModalVisible: false,
    isLegendOpen: false,
    sendMultipleDoc:false,
    isclearTransactionLaneData:false,
  });
  const [page, setPage] = useState([1]);

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();

  useEffect(()=>{
    const params = {
      ...(item?.offerId ? { offerId: item?.offerId } : {}),
      ...(item?.isProperty || item?.isErts  ? item?.persistId ? { ertsId: item?.persistId } : item?.propertyId   ? { propertyId: item?.propertyId } : {}: {}),
      ...(item?.thirdpartyId ? { thirdpartyIdDoc: item?.thirdpartyId } : {}),
      openFor: item?.isProperty || item?.isErts ? "SELLER_AGENT" : "BUYER_AGENT",
      ...(delegate ? {delegateSide: (item.isProperty || item?.isErts)? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id} : {}),
     section: "PROPERTY_DOCS"
    };
    if (item?.offerId || item?.persistId || item?.propertyId) {
      dispatch(PropertyHandlers.getPropertyDocsFun({ params, unmounted, source }));
    }
    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [updatedPropertyDoc, uploadedPdfDocSuccess])
  useEffect(() => {
    const delegateSide = section === "DELEGATE" && role;
    if (item?.offerId || item?.persistId || item?.propertyId) {
      dispatch(transactionLaneDataHandle.getIndividualTransaction({ offerId: item?.offerId, ertsId: item?.persistId, propertyId: item?.propertyId, setLoading, unmounted, source, delegateSide, delegateOwnerId: delegateSide && item?.currentAgentId?._id }));
    }
    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    };
  }, []);
  // Property Docs functions
  const handleActivePropertyDocsOk = () => {
    setIsModalOpen({ ...isModalOpen, propertyDocs: false });
  };

  const handleActivePropertyDocsCancel = () => {
    setIsModalOpen({ ...isModalOpen, propertyDocs: false });
   };

  const handleuploadmodal = () => {
    setNestedModalOpen({ ...nestedModalOpen, isUploadDocsModal: true });
    const type="SendEmail"
    const params = {
      ...(item?.offerId ? { offerId: item?.offerId } : {}),
      ...(item?.isProperty || item?.isErts  ? item?.persistId ? { ertsId: item?.persistId } : item?.propertyId   ? { propertyId: item?.propertyId } : {}: {}),
      ...(item?.thirdpartyId ? { thirdpartyIdDoc: item?.thirdpartyId } : {}),
      openFor: item?.isProperty || item?.isErts ? "SELLER_AGENT" : "BUYER_AGENT",
      personId: agentDetails?._id,
      ...(delegate ? {delegateSide: (item.isProperty || item?.isErts)? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id} : {}),
    };
    dispatch(PropertyHandlers.getPropertyDocsFun({params, unmounted, source,type}));
   return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  };
  const allBuyerAgentData = useMemo(() => {
    let agents=[]
    agents = getAgentsArrayFromIndividualTxnData({
      agentType:'SELLER_AGENT',

      txnData: getIndividualTransactionData,
    });
   return agents;
  }, [getIndividualTransactionData]);

  const allSellerAgentData = useMemo(() => {
    let agents=[]
    agents = getAgentsArrayFromIndividualTxnData({
      agentType: 'BUYER_AGENT',

      txnData: getIndividualTransactionData,
    });
    return agents
  }, [getIndividualTransactionData]);
  // const handleShareIconClick = (ele, source) => {
  //   setCurrentPropertyDocId({ clickedItem: ele, source });
  //   setNestedModalOpen({ ...nestedModalOpen, isEmailModalVisible: true });
  // };

  // ---------------  functions to open the files ---------------------
  const handlePropertyDocs = (ele) => {
    let buildData = {
      documentId: ele.documentId,
      contractType: ele.contractType,
      contractId: ele.contractId,
      openFor: item?.isProperty || item?.isErts ? "SELLER_AGENT" : "BUYER_AGENT",
      token: localStorage.getItem("userAuth"),
      builtForId: agentDetails?._id,
      key: agentDetails?._id,
      ...(item.offerId ? { offerId: item.offerId } : {}),
      ...(delegate ? { delegateOwnerId: agentDetails?._id } : {}),
      builtForSection: "DOCUMENT",
      signable: true,
      ...(ele.RTDInitiator && {  RTDInitiator: ele.RTDInitiator}),
      ...(item?.propertyId ? { propertyId: item?.propertyId } : {}),
      ...(item?.thirdpartyId ? { thirdpartyId: item?.thirdpartyId } : {}),
    };
    dispatch(transactionLaneDataHandle.generateUrlIdAndRedirect({ buildData, currentPath: location?.pathname }));
  };

  const handleOPenDocs = (ele) => {
    if (ele?.fileUrl) {
      window.open(ele?.fileUrl);
    }
  };

  const handleShareUnshareFileConfirm = (ele, checkBoxValue ) => {
    confirm({
      title: checkBoxValue
      ? ` Are you sure you want to show this file in the Shared folder?`
      : `Are you sure you want to remove this file from the the Shared folder?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleShared(ele);
      },
      onCancel() {},
    });
  };

// The common function used in handleconfirm & handleconfirm2
  const handleShared = (ele) => {
    const params = {
      ...(item?._id ? { propertyDocId: ele._id } : {}),
      ...(item?.offerId ? { offerId: item?.offerId } : {}),
      ...(item?.persistId ? { ertsId: item?.persistId } : {}),
      ...(item?.thirdpartyId ? { thirdpartyIdDoc: item?.thirdpartyId } : {}),
      ...(ele.isShared ? { unShare: "true" } : {}),
      ...(delegate ? {delegateSide: (item.isProperty || item?.isErts)? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id} : {}),
    };

    if (item?.offerId) {
      dispatch(PropertyHandlers.sharePropertyDocs(params, true));
    } else {
      dispatch(PropertyHandlers.sharePropertyDocs(params, true));
    }
  };

//   Function to Upload doc will be passed as prop
  const uploadTemp = (setShowInputFileName,fileNametext,setFileNametext) => {
    setShowInputFileName(false);
    uploadFile.current.append("filename", fileNametext);
    if (item?.offerId) uploadFile.current.append("offerId", item?.offerId);
    if (item?.persistId) uploadFile.current.append("ertsId", item?.persistId);
     uploadFile.current.append("personId",agentDetails?._id);

    if(delegate){
      if (item.isProperty || item?.isErts) {
        uploadFile.current.append("delegateSide", "SELLER")
        uploadFile.current.append("delegateOwnerId", agentDetails?._id)
      } else {
        uploadFile.current.append("delegateSide", "BUYER");
        uploadFile.current.append("delegateOwnerId", agentDetails?._id)
      }
    }
    if (item?.thirdpartyId) uploadFile.current.append("thirdpartyIdDoc", item?.thirdpartyId);
    if (item?.isProperty) uploadFile.current.append("propertyId", item?._id);
    uploadFile.current.append("openFor", (item?.isProperty || item?.isErts) ? "SELLER_AGENT":"BUYER_AGENT");

    dispatch(PropertyHandlers.uploadPropertyDocsFun(uploadFile.current));
    setFileNametext("");
    setNestedModalOpen({...nestedModalOpen,isUploadDocsModal:false});
  };

  const checkForDisable = checkIfDisabled({openFor: (item?.isProperty || item?.isErts) ? "SELLER" : "BUYER", actualPropertyStatus: item?.actualPropertyStatus, buyingStatus: item?.actualOfferStatus, offerStatus: item?.status});
  const handleCreateDocCancel = () => setNestedModalOpen({ ...nestedModalOpen, isUploadDocsModal: false })

  // Function To Delete Document from propertyDocs folder

  const handleDeletePropertyDocs = (ele, docType) => {
    // Determine the message and content based on requiresWholeReRender
    const isWholeReRenderRequired = ele.contractType === "ERTS" || createOfferContractTypes.includes(ele.contractType);
    
    const confirmationMessage = isWholeReRenderRequired
      ? (
        <>
          Are you sure you want to delete this <span style={{ color: 'red' }}>{ele?.contractType}</span> document? This action will affect all other documents
        </>
      )
      : "Are you sure you want to delete this document?";
    
    const confirmationContent = isWholeReRenderRequired 
        ? "Note: This action is irreversible. Deleting this document will permanently remove it from your account and all associated documents." 
        : "Note: This action is irreversible. Deleting this document will permanently remove it from your account.";

    confirm({
      title: confirmationMessage,
      content: confirmationContent,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        if (docType === 'uploadedDoc') {
          dispatch(PropertyHandlers.removePropertyDocsFun(ele?._id));
        } else if (docType === 'contractDoc') {
          const payloadData = {
            documentId: ele?.documentId,
            ...(delegate ? {delegateSide: (item.isProperty || item?.isErts)? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id} : {}),
            ...(item?.isProperty && { propertyId: item?.propertyId }),
            ...(item?.isErts && { ertsId: item?.persistId }),
            ...(item?.isOffer && { offerId: item?.offerId }),
            ...(item?.isDraftOffer && { offerId: item?.offerId }),
            requiresRender: true,
            requiresWholeReRender: isWholeReRenderRequired,
            filterSort
          };
          dispatch(PropertyHandlers.deletePropertyContractDocs({ payloadData }));
        }
      },
      onCancel() {},
    });
};

  const handleToggleDashboard = (ele, checkBoxValue) => {
   
    const payloadData = {
      documentId: ele?.documentId,
      isToggleFrom: "PROPERTY_DOCS",
      ...(delegate ? {delegateSide: (item.isProperty || item?.isErts)? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id} : {}),
      ...(item?.isProperty && { propertyId: item?.propertyId }),
      ...(item?.isErts && { ertsId: item?.persistId }),
      ...(item?.isOffer && { offerId: item?.offerId }),
      ...(item?.isDraftOffer && { offerId: item?.offerId }),
      requiresRender: true,
      showInTransactionDashboard: checkBoxValue
    };
    confirm({
      title: checkBoxValue
        ? `Are you sure you want to move this document to the transaction dashboard?`
        : `Are you sure you want to remove this document from the transaction dashboard?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(PropertyHandlers.toggleDocumentInTransactionDashboard({ payloadData }));
      },
      onCancel() { },
    });
  };
  
  return (
    <>
      
      {/* Main property docs modal  */}
      <Modal
        style={{ top: "20px", height: "500px" }}
        width={750}
        title={
          <div
            className="documentModal"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "grey",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            <div style={{ width: "40%", textAlign: "left" }}>
              <FolderOutlined
                style={{
                  fontSize: isSmallScreen ? "18px" : "22px",
                  color: "rgb(8,81,145)",
                }}
              />
            </div>
            <div style={{ width: "100%", textAlign: "center", fontSize: isSmallScreen ? "15px" : "20px", color: "#178DFA" }}>
              Property Docs
            </div>
            <div
              style={{
                width: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: isSmallScreen ? "10px" : "20px",
                marginRight: "10px",
              }}
            >
              <Button
                onClick={() =>
                  setNestedModalOpen({
                    ...nestedModalOpen,
                    sendMultipleDoc: true,
                    isEmailModalVisible: true,
                  })
                }
                icon={
                  <MailOutlined
                    style={{ fontSize: isSmallScreen ? "16px" : "25px" }}
                  />
                }
                style={{
                  color: "#085191",
                  border: "none",
                }}
              />
              <Button
                className="borderless-button"
                style={{ border: 0 }}
                icon={
                  <PlusCircleFilled
                    style={{
                      fontSize: isSmallScreen ? "16px" : "25px",
                      color: "#085191",
                    }}
                    {...(checkForDisable?.disabled
                      ? { onClick: disabledTransactionText }
                      : { onClick: () => handleuploadmodal() })}
                  />
                }
              />
            </div>
          </div>
        }
        footer={false}
        open={isModalOpen?.propertyDocs}
        onOk={handleActivePropertyDocsOk}
        onCancel={handleActivePropertyDocsCancel}
        className="documentModal"
      >
        <div style={{ height: "500px", marginTop:"-20px" }} className="documentModal">
          {propertDocsLoading ? (
            <div style={{ width: "100%", fontSize: "15px", height: "40%", paddingTop: "10%", textAlign: "center" }}>
              <Spin />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                color: "black",
                height: "100%" ,
                overflowY: "auto",
                overflowX: "hidden",
                paddingRight: "5px",
              }}
            >
              <PropertyDocsTable
                documents={propertyDocs}
                role={role}
                item={item}
                handleOPenDocs={handleOPenDocs}
                handlePropertyDocs={handlePropertyDocs}
                handleShareUnshareFileConfirm={handleShareUnshareFileConfirm}
                handleToggleDashboard={handleToggleDashboard}
                handleDeletePropertyDocs={handleDeletePropertyDocs}
                isSmallScreen={isSmallScreen}
              />
            </div>
          )}
        </div>
      </Modal>

      {/* upload docs modal here  */}
      {
        nestedModalOpen?.isUploadDocsModal && <Modal
          width={800}
          open={nestedModalOpen?.isUploadDocsModal}
          footer={false}
          onCancel={handleCreateDocCancel}
          style={{marginTop: "-30px"}}
        >
          <div
            style={{
              textAlign: "center",
              color: "#888888",
              height: "72vh",
              fontSize: "18px",
              padding: "5px",
              marginTop: "10px",
              // boxShadow:
              //   "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              // borderTopLeftRadius: "5px",
              // borderTopRightRadius: "5px",
              // borderBottomRightRadius: "5px",
              // borderBottomLeftRadius: "5px",
              // overflowY: "auto"
            }}
          >
            <Button
              icon={<ArrowLeftOutlined />}
              style={{
                position: "absolute",
                height: "5px",
                fontSize: "15px",
                cursor: "pointer",
                top: "0px",
                left: "0px",
                zIndex: "1",
                border: "none",
              }}
              onClick={() => {
                // Handle the back button click event here
                setNestedModalOpen({
                  ...nestedModalOpen,
                  isUploadDocsModal: false,
                });
              }}
            >
              <text style={{ color: "gray", marginLeft: "5px" }}>Back</text>
            </Button>
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", width: "100%", fontSize: "15px" }}>
                <Tabs
                  defaultActiveKey="1"
                  style={{ width: "100%", height: "calc(85vh - 8rem)" }}
                  className="centered-tabs"
                >
                  <TabPane
                    tab="Create New"
                    key={"1"}
                    style={{ width: "100%", height: "100%" }}
                    // disabled={role === "BUYER" ? true : false}
                  >
                    <CreateDocs role={role} item={item} section={section} handleCreateDocCancel={handleCreateDocCancel} loading={loading}/>
                  </TabPane>
                  <TabPane
                    tab="Upload"
                    key={"2"}
                    style={{ width: "100%", height: "100%" }}
                  >
                  <div style={{width:"100%",margin:"auto",marginTop:"20px",display:"flex",justifyContent:"center"}}>
                    <DraggerComp
                    uploadFile={uploadFile}
                    isModalOpen={isModalOpen}
                    setNestedModalOpen={setNestedModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    uploadTemp={uploadTemp}
                    accept=".pdf,image/*"
                    />
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </Modal>
      }


      <SignableAndReadOnly
        role={role}
        section={section}
        source={"PROPERTY_DOCS"}
        docSelected={true}
        setPropertyDocsModalOpen={setIsModalOpen}
        nestedModalOpen={nestedModalOpen}
        setNestedModalOpen={setNestedModalOpen}
        currentPropertyDocId={currentPropertyDocId}
        setCurrentPropertyDocId={setCurrentPropertyDocId}
        selectedData={item}
        delegateOwnerId={delegate &&  agentDetails?._id}
        documentId={currentPropertyDocId.documentId}
       delegate={ delegate ? ((item?.isProperty || item?.isErts) ? { delegateSide: "SELLER" } : { delegateSide: "BUYER" }) : {} }
       allBuyerAgentData={allBuyerAgentData}
      allSellerAgentData={allSellerAgentData}
      page={page}
      setPage={setPage}
      />
    </>
  );
};

export { PropertyDocsModal };

