/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {  useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../../Assets/Icons/newLogo.png";
import { Link } from "react-router-dom";
import {
  Avatar,
  Menu,
  Dropdown,
  Typography,
  Modal,
  Spin,
} from "antd";
import { UserOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { userSignUpAction } from "../../User/UserAuthenticate/state/actions";
import { accountHandle } from "../../Account/state/actions";
import { appStatusChange } from "../state/actions";
import DebugModal from "./DebugModal";
import styles from "../styles/Navbar.module.css";
import dotEnv from "dotenv";
import {io} from "socket.io-client";
import { getCategoryDataHandle } from "../../PropertyComp/utils/helper";
import NotificationDrawer from "./NotificationDrawer";
import { generateInitialsFromObj } from "../../Common/utils/extraFunctions";
import { hasAccessAccountForAllUsers } from "../../Common/commondata/commonData";

dotEnv.config();

// const { Header } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

function Navbar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [tabName,setTabName] = useState(location.pathname);

  const logout = () => {
    dispatch(userSignUpAction.logOut(history));
  };

  const { getUserDetailsData, userId } = useSelector((state) => state.account);
  const { loadOpacityLoader } = useSelector((state) => state.app);
  const socketRef = useRef(null);
  let currentSocket = socketRef.current;
  
  useEffect(() => {
    if (userId && !currentSocket) {
      console.log("coming socket inside", userId)
        currentSocket = io(`${process.env.REACT_APP_SOCKET_BASE_URL}`);
        currentSocket?.emit('user_connected', {userId: String(userId) });
        let currentConfirmationModal = null;
        currentSocket.on('common_event', (eventData) => {
          const { message } = eventData;
          let confirmationMessage = message || "";
          // console.log("confirmationMessage", message);
          if (currentConfirmationModal) {
            currentConfirmationModal.destroy();
          }
          currentConfirmationModal = confirm({
            title: `${confirmationMessage}`,
            content: `Do you want to update the content with the latest data?`,
            okText: 'Yes',
            cancelText: 'No',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              const { category, categoryId, status } = getCategoryDataHandle({ notificatonData: eventData })
              if (category && categoryId) {
                history.push({ pathname: "/transactions", state: { category, categoryId, status} });
              } else {
                history.push({ pathname: "/transactions" });
              }
            },
            onCancel() {
            },
          });
        });
    }
    return () => {
      if (currentSocket) {
        currentSocket.disconnect();
      }
    };
  }, [userId]);

  useEffect(() => {
      dispatch(appStatusChange.getNotificationtData());
      dispatch(appStatusChange.getNotificationtCountData());
      dispatch(appStatusChange.getAppVersion());
  }, []);

  const goToAccount = () => {
    dispatch(accountHandle.loginAnotherAccount({ logMeBack: true}));
  };
  const isAccessAccount = getUserDetailsData?.delegateId?._id && !hasAccessAccountForAllUsers(getUserDetailsData);
  const menu = (
    <>
      {location.pathname === "/license" ? null : (
        <Menu>
          <Menu.Item key="0" disabled style={{ fontSize: "16px" }}>
            Hi{" "}
            {getUserDetailsData && getUserDetailsData?.firstName
              ? getUserDetailsData?.firstName
              : ""}
          </Menu.Item>
          {
            isAccessAccount    ? "" : <Menu.Item key="1" style={{ fontSize: "16px" }}>
              <Link to="/account" style={{ fontSize: "16px" }}>
                <span>Account</span>
              </Link>
            </Menu.Item>
          }
          {
            isAccessAccount ? "" : <Menu.Item key="3">
              <Link to="/changePassword" style={{ fontSize: "16px" }}>
                <span>Change Password</span>
              </Link>
            </Menu.Item>
          }
          {
            getUserDetailsData?.delegateId?._id && <Menu.Item key="6" onClick={goToAccount} style={{ fontSize: "16px" }}>
              Switch back to my account
            </Menu.Item>
          }
          {getUserDetailsData?.currentWebVersion && <Menu.Item key="4" style={{ fontSize: "16px" }}>
            Ver {getUserDetailsData?.currentWebVersion}
          </Menu.Item>}
          <Menu.Item key="5" onClick={logout} style={{ fontSize: "16px" }}>
            Logout
          </Menu.Item>
        </Menu>
      )}
    </>
  );

  const logoutDelegate = (data) => {
    confirm({
      title: `Are you sure you want to logout ${data.fullName} from using your account?`,
      content: `Note: ${data.fullName} can access your account by logging in again`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(accountHandle.logoutDelegate(data.personId));
      },
      onCancel() {},
    });
  };

  const usingAccount = (data) => {
    return (
      <Menu style={{ width: "180px" }}>
        <Menu.Item disabled style={{ fontSize: "16px" }}>
          Account Being used by {data.fullName}
        </Menu.Item>
        <Menu.Item
          style={{ fontSize: "16px" }}
          onClick={() =>
            logoutDelegate({ fullName: data.fullName, personId: data._id })
          }
        >
          Logout {data.fullName}
        </Menu.Item>
      </Menu>
    );
  };

  const getTabName = () => {
    if(location.pathname.includes("property")){
      if(location.pathname.includes("documents")){
        setTabName("RTD")
      }else{
        setTabName("TRANSACTIONS");
      }
    }else if(location.pathname.includes("tools")){
      if(location.pathname.includes("docsClause")){
        setTabName("DOCS_CLAUSE")
      }else if(location.pathname.includes("email_drip_campaign")){
        setTabName("EMAIL_DRIP_CAMPAIGN")
      }else if(location.pathname.includes("email_template")){
        setTabName("EMAIL_TEMPLATE")
      }else if(location.pathname.includes("safe-T-drive")){
        setTabName("SAFE_T_DRIVE")
      }else{
        setTabName("TOOLS")
      }
    }else if(location.pathname.includes("calendar")){
      setTabName("CALENDAR")
    }else if(location.pathname.includes("client")){
      setTabName("CLIENT")
    }else{
      setTabName("HOME")
    }
  }
  useEffect(() => {
    getTabName()
  },[location])

  return (
    <>
      {
        loadOpacityLoader && <div style={{ position: "fixed", zIndex: "5000", width: "100%", height: "100vh", backgroundColor: "black", opacity: "0.4", display: "flex", gap: "20px", flexDirection:"column", justifyContent: "center", alignItems: "center" }}>
          <Spin size="large" />
        </div>
      }
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "hsl(208deg 89% 30%)",
          height: "50px",
          lineHeight: "50px",
          padding: "10px 5px 10px 30px",
          position: "sticky",
          top: 0,
          zIndex: 10,
          width: "100%",
          transition: "all .5s ease-in-out",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            transition: "all .5s ease-in-out",
            color: "rgb(78, 115, 223)",
          }}
        >
          <Link to={location.pathname === "/license" ? "/license" : "/"}>
            <img
            className={styles.resurelogo}
              src={Logo}
              alt="Logo"
            />
          </Link>
        </div>
        {
          (getUserDetailsData?.subscriptionData?.activePlan === "RESURE_TRIAL") ? <div style={{color: "black", backgroundColor: "orange", height: "30px", padding: "0px 10px", display: "flex", alignItems: "center", letterSpacing: "0.5px", borderRadius: "5px", fontSize: "13px", fontWeight: 500}}>TRIAL ACCOUNT</div> : ""
        }

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            color: "#fff",
          }}
        >
          <Text
            key="7"
            className={styles.realterName}
            style={{ color: "white",maxWidth:"500px" }}
            ellipsis
          >
            <span>
              <span style={{ fontSize: "22px",color:"#e4c45b" }}>
                {getUserDetailsData?.delegateId?.fullName
                ? `${getUserDetailsData?.delegateId?.fullName}`
                : ""}
                </span>
                {getUserDetailsData?.delegateId?.fullName ?
                <span style={{fontSize:"17px"}}> logged in as </span> : null}
            </span>
            <span style={{ fontWeight: 500 }}>
              {(getUserDetailsData && getUserDetailsData?.fullName) || ""}
            </span>
          </Text>
          <Dropdown
            key="4"
            overlay={menu}
            trigger={["click", "hover"]}
            style={{ border: "1px solid white" }}
          >
            {getUserDetailsData &&
              getUserDetailsData?.profileImg &&
              getUserDetailsData?.profileImg?.original
                ? <Avatar
                key="5"
                src={getUserDetailsData?.profileImg?.original}
                style={{ cursor: "pointer",marginLeft:"-5px" }}
                size="large"
                icon={<UserOutlined style={{ fontSize: "24px" }} />}
              /> : <Avatar key="5" style={{ cursor: "pointer",marginLeft:"-5px",color:"#085190",border:"1px solid #085190",fontSize:"18px" }}
                size="large">
              {generateInitialsFromObj(getUserDetailsData)}
              </Avatar>
            }
          </Dropdown>
          {
            getUserDetailsData &&
            !getUserDetailsData?.delegateId &&
            getUserDetailsData?.delegateUsingMyAccount?.length && 
            getUserDetailsData?.delegateUsingMyAccount?.map((delegate) => 
              delegate?.delegateUsingMyAccount?.profileImg?.original ? (
                <Dropdown
                  overlay={() =>
                    usingAccount(delegate.delegateUsingMyAccount)
                  }
                  trigger={["click", "hover"]}
                >
                  <Avatar
                    style={{
                      cursor: "pointer",
                      border: "3px solid red"
                    }}
                    src={
                      delegate.delegateUsingMyAccount.profileImg
                        .original
                    }
                    size="large"
                    icon={<UserOutlined style={{ fontSize: "20px" }} />}
                  />
                </Dropdown>
              ) : (
                <Dropdown
                  overlay={() =>
                    usingAccount(
                      delegate.delegateUsingMyAccount
                    )
                  }
                  trigger={["click", "hover"]}
                >
                  <Avatar
                    size="medium"
                    style={{
                      background: "#5080c8",
                      // position: "static",
                      // marginLeft: "-15px",
                      cursor: "pointer",
                      border: "3px solid red"
                    }}
                    icon={<UserOutlined style={{ fontSize: "20px" }} />}
                  />
                </Dropdown>
              ))
          }
          <NotificationDrawer />
        </div>
      </div>
    </>
  );
}
export { Navbar };
