import React, { useEffect, useState } from "react";
import { List, Avatar, Input, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "../../Client/styles/Client.module.css";
import { useDispatch, useSelector } from "react-redux";
import { formatAddress } from "../../Common/utils/extraFunctions";
import { accountHandle } from "../state/actions";
import { Loading } from "../../Common/components/Loading";
const { Title } = Typography;
const FranchiseList = ({
  selectClient,
  dataSource,
  currentTag,
  setDataSource,
  searchFranchiseText,
  searchFirmText,
  setSearchFirmText
}) => {
  const { getFranchiseData,getBrokrageData,getOfficeData,getFranchiseLoading, getBrokrageLoading,getOfficeLoading} =
    useSelector((state) => state.account);
  const[selectedData,setSelectedData]=useState("")
  const [showSearchInput, setShowSearchInput] = useState(true);
  const [listCount, setListCount] = useState("");
  const dispatch=useDispatch()

  let timeoutId;
  function debounce(fn, delay) {
    return function () {
      const args = arguments;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  }

  // handling on the text Change on searchInput box
  const searchTextChange = () => {
    let queryValue = searchFirmText?.toLowerCase();

    let query={} 
    if (currentTag === "BROKERAGE") {
      if(queryValue){
      query={
        officeTypes:"BROKERAGE",
        parentId:selectedData._id,
        search:queryValue,
         }
        }else{
      query={
        officeTypes:"BROKERAGE",
        parentId:selectedData._id
        }
    }
      dispatch(accountHandle.getBrokrage(query));
      setDataSource(getBrokrageData);
    } else if (currentTag === "OFFICE") {
      if(queryValue)
      {
     query={
        officeTypes:"OFFICE",
        search:queryValue,
        parentId:selectedData._id
      }
    }
    else{
      query={
        officeTypes:"OFFICE",
        parentId:selectedData._id
      }
    }
      dispatch(accountHandle.getOffice(query));
      setDataSource(getOfficeData);
      
    }
  };

  const debouncedSearchBrokerage = debounce(searchTextChange, 800);

  let handleLoadMore = () => {
    setListCount(listCount + 32);
  };
  useEffect(() => {
    if (searchFranchiseText) {
      searchTextChange(searchFranchiseText);
    }
  }, [searchFranchiseText]);

  useEffect(() => {
    if(searchFirmText?.length > 0){
      debouncedSearchBrokerage();
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [searchFirmText]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div>
          <SearchOutlined
            style={{ fontSize: "30px", color: "#085191" }}
            onClick={() => setShowSearchInput(!showSearchInput)}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <Title level={3}>
            {currentTag === "BROKERAGE"
              ? "Select Brokerage"
              : currentTag === "OFFICE"
              ? "Select Office"
              : ""}
          </Title>
        </div>
        <div></div>
      </div>
      <div>
        {showSearchInput && (
          <Input
            onChange={(e) => setSearchFirmText(e.target.value)}
            value={searchFirmText}
            placeholder="Search by name"
            autoFocus
            allowClear
            style={{
              height: "40px",
              border: "1px solid #085191",
              fontSize: "20px",
            }}
          />
        )}
      </div>

      <div
        className={
          showSearchInput
            ? "mozScrollbar clientListBox"
            : "mozScrollbar clientListBoxForSmallScreen"
        }
        id="parent-scroll-div"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
        }}
        onScroll={(e) => {
          if (
            Math.round(e.target.scrollHeight - Math.round(e.target.scrollTop)) <
            1000
          ) {
            handleLoadMore();
          }
        }}
      >
       {getBrokrageLoading||getOfficeLoading ?<Loading/>:<List
          className={styles.demoloadlisting}
          bordered={true}
          itemLayout="horizontal"
          dataSource={currentTag === "BROKERAGE" ? getBrokrageData : currentTag === "OFFICE" ? getOfficeData : []}
          renderItem={(item) => {
            const nameArr = item?.name?.split(" ");
            const fullName = nameArr?.[0]?.[0]?.toUpperCase() + nameArr?.[nameArr.length - 1]?.[0]?.toUpperCase();
            return (
              <List.Item
                className={[styles.clientListItem, styles.listuser]}
                // style={{
                //   background: item._id === selectedClient ? "#92b3d1" : "white",
                // }}
                key={item._id}
                onClick={() => {
                  selectClient(item)
                  setSelectedData(item);
                }}
              >
                <List.Item.Meta
                  avatar={
                    item.officeLogo ? (
                      <Avatar
                        style={{
                          border: "3px solid #085191",
                          background: "white",
                          color:"#085191",
                          objectFit:"contain"
                        }}
                        size={55}
                        className={styles.useravtar}
                        src={item?.officeLogo}
                      >
                        {fullName}
                      </Avatar>
                    ) : item?.name ? (
                      <Avatar size={55}
                      style={{
                        border: "3px solid #085191",
                        background: "white",
                        color:"#085191",
                        objectFit:"contain"
                      }}>
                        {fullName}
                      </Avatar>
                    ) : (
                      <Avatar size={55} 
                      style={{
                        border: "3px solid #085191",
                        background: "white",
                        color:"#085191",
                        objectFit:"contain"
                      }}/>
                    )
                  }
                  title={item?.name}
                  description={
                    <span style={{ color: "#4CAAFF", fontSize: "16px" }}>
                      {formatAddress(item?.address)}
                    </span>
                  }
                />
              </List.Item>
            );
          }}
        />}
      </div>
    </div>
  );
};

export default FranchiseList;
