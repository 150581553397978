import { v4 as uuidv4 } from "uuid";

export const getNeedToKnowRoute = (pathname) => {
  try {
    if (!pathname || typeof pathname !== "string") {
      return "";
    }
    let needToKnowRoute = pathname.split("/");
    if (needToKnowRoute[2] === "documents") {
      return "/" + needToKnowRoute[1] + "/needtoknow/";
    } else {
      return "/" + needToKnowRoute[1] + "/" + needToKnowRoute[2] + "/needtoknow/";
    }
  } catch (error) {
    return "";
  }
};


export const getCombinedAgentsData = ({ getIndividualTransactionData = {}, agentData = [], agentList = [], agentType="" }) => {
  const transactionData = getIndividualTransactionData?.newTransactionData || {};
  const agentMap = agentData.reduce((acc, agent) => {
    if (agent?.profileImg?.original) {
      acc[String(agent._id)] = agent.profileImg.original;
    }
    return acc;
  }, {});

  const updatedAgents =
    transactionData[agentType]?.map((el) => {
      let profileImg;
      if (typeof el.personId === "string" && el.personId.length === 24) {
        profileImg = agentMap[el.personId];
      }
      return {
        ...el,
        ...(profileImg ? { profileImg } : {}),
      };
    }) || [];
  agentList.push(...updatedAgents);
};


