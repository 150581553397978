import React, { useState } from "react";
import styles from "../styles/Client.module.css";
import { Tooltip, Typography } from "antd";
import { formatPhoneNumber } from "../../Common/utils/extraFunctions";
import { PhoneDetails } from "./PhoneDetails";
import { BsPhone } from "react-icons/bs";
import { SlPhone } from "react-icons/sl";



const Phone = ({ setModelDrawer,modelDrawer, personDetails, selectedClient, source  }) => {
  const Text = Typography;
  return (
    <Text style={{ display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
      <Tooltip title={source === 'officePhoneNumber' ? "Office No." : "Mobile No."} placement="left">
      {source === 'officePhoneNumber' ? <SlPhone className={styles.textAlignment} /> : <BsPhone className={styles.textAlignment} />}
      </Tooltip>
      </div>
      <div
        className={styles.iconsbox}
        onClick={() =>
          setModelDrawer({
            status: true,
            component: (
              <PhoneDetails
                setModelDrawer={setModelDrawer}
                modelDrawer={modelDrawer}
                data={personDetails}
                selectedClient={selectedClient}
                section="CLIENT"
                source={source}
              />
            ),
            title: "PHONE DETAILS",
          })
        }
      >
        <div className={styles.iconsclient}>
          {/* <img src="icons/client-details-phone.svg" alt=""></img> */}
          {/* <FaPhoneAlt className={styles.textAlignment} />  */}
        </div>
        {source==='officePhoneNumber' ? 
          (<div style={{ marginLeft: "-10%" }} className={styles.userfeild}>
            {personDetails.officePhoneNumber?.phoneNumber && Object.keys(personDetails.officePhoneNumber?.phoneNumber)?.length > 0 
            // &&
            // personDetails?.officePhoneNumber?.phoneNumber.length === 10
              ? formatPhoneNumber(personDetails?.officePhoneNumber?.phoneNumber)
              : "Enter Office No."}
          </div>) : 
          (<div style={{ marginLeft: "-10%" }} className={styles.userfeild}>
            {personDetails?.mobilePhoneNumber?.phoneNumber && Object.keys(personDetails?.mobilePhoneNumber?.phoneNumber).length > 0
            //  &&
            // personDetails?.mobilePhoneNumber?.phoneNumber?.length === 10
              ? formatPhoneNumber(personDetails?.mobilePhoneNumber?.phoneNumber)
              : "Enter Mobile No."}
          </div>)
        }
      </div>
    </Text>
  );
};

export { Phone };
