import React, { useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal, Input, Col, Row } from "antd";
import { accountHandle } from "../state/actions";
import { useEffect } from "react";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import FranchiseList from "./FranchiseList";
// import { Loading } from "../../Common/components/Loading";
const { confirm } = Modal;

function FranchiseDetails({ setModelDrawer }) {
  const dispatch = useDispatch();
  const [franchiseInput, setFranchiseInput] = useState("");
  const franchiseHandle = useRef(false);
  const [brokerageInput, setbrokerageInput] = useState("");
  const brokerageHandle = useRef(false);
  const officeHandle = useRef(false);
  const [searchText, setSearchText] = useState("");
  const [officeInput, setOfficeInput] = useState("");
  const brokrageShow = useRef(false);
  const officeShow = useRef(false);
  const currentTag = useRef("BROKERAGE");
  const [currentSelectedFranchise, setCurrentSelectedFranchise] = useState("");
  const [showListModal, setShowListModal] = useState(false);
  const {
    getUserDetailsData,
    getFranchiseData,
    getBrokrageData,
    getOfficeData
  } = useSelector((state) => state.account, shallowEqual);
  const [dataSource, setDataSource] = useState(getFranchiseData);
  const [franchiseDataLength,setFranchiseDataLength]=useState(null)
  const [error, setError] = useState({
    franchiseStatus: false,
    brokerageStatus: false,
    officeStatus: false,
    message: "",
  });
  const [selectedData,setSelectedData] = useState({});
  const [searchFirmText, setSearchFirmText] = useState("");

  useEffect(() => {
  //   let query={
  //     officeTypes:"FRANCHISE"
  //   }
  //   dispatch(accountHandle.getAllFranchise(query));
  //  setFranchiseDataLength(getFranchiseData.length)
  }, []);

  useEffect(() => {
   
    // if (currentTag.current === "FRANCHISE") {
    //   setDataSource(getFranchiseData);
    // }
    if (currentTag.current === "BROKERAGE") {
      setDataSource(getBrokrageData);
    }
 }, [getBrokrageData]);
  
  // when user selects any firms
  const hnadleFranchiseSelected = (selectedFranchise) => {
    setSelectedData(selectedFranchise)
   let query={}
    // if (currentTag.current === "FRANCHISE") {
    //   if(searchText){
    //     query={
    //       officeTypes:"BROKERAGE",
    //       parentId:selectedFranchise._id,
    //       search:searchText
    //     }
    //   }else{
    //   query={
    //     officeTypes:"BROKERAGE",
    //     parentId:selectedFranchise._id,
    //     }
    //   }
    //   // dispatch(accountHandle.getBrokrage(query))
    //   setSearchText("");
    //   setFranchiseInput(selectedFranchise.name);
    //   setError({ ...error, franchiseStatus: false, message: "" });
    //   // setDataSource(getBrokrageData);
    //   franchiseHandle.current = true;
    //   currentTag.current = "BROKERAGE";
    //   brokrageShow.current = true;
    // } else 
    if (currentTag.current === "BROKERAGE") {
      if(searchText){
        query={
          officeTypes:"BROKERAGE",
          parentId:selectedFranchise._id,
          search:searchText
        }
      }else{
      query={
        officeTypes:"BROKERAGE",
        parentId:selectedFranchise._id,
        }
      }
      // dispatch(accountHandle.getBrokrage(query))
      // setDataSource(getBrokrageData);
      // dispatch(accountHandle.getOffice(query))
      // setDataSource(getOfficeData);
      setbrokerageInput(selectedFranchise.name);
      officeShow.current = true;
      brokerageHandle.current = true;
      currentTag.current = "OFFICE";
      setSearchText("");
      setError({ ...error, brokerageStatus: false, message: "" });
    } else if (currentTag.current === "OFFICE") {
      if(searchText){
        query={
          officeTypes:"OFFICE",
          parentId:selectedFranchise._id,
          search:searchText
        }
      }else{
       query={
          officeTypes:"OFFICE",
          parentId:selectedFranchise._id,
          
        }
      }
      // dispatch(accountHandle.getOffice(query))
      setDataSource(getOfficeData);
      setOfficeInput(selectedFranchise.name);
      setError({ ...error, officeStatus: false, message: "" });
      officeHandle.current = true;
      setCurrentSelectedFranchise(selectedFranchise);
      setSearchText("");
    }
    setSearchFirmText("")
    setShowListModal(false);
  };

  const onFinish = (values) => {};
  // When user Clicks on the save button
  const handleSubmit = () => {
    // if (!franchiseInput) {
    //   setError({
    //     ...error,
    //     franchiseStatus: true,
    //     message: "Please Select Franchise!",
    //   });
    //   return;
    // }
    if (!brokerageInput) {
      setError({
        ...error,
        brokerageStatus: true,
        message: "Please Select Brokerage!",
      });
      return;
    }
    if (!officeInput) {
      setError({
        ...error,
        officeStatus: true,
        message: "Please Select  Office!",
      });
      return;
    }
    if (getUserDetailsData?.office?._id) {
      showConfirmModal();
      return;
    }
    let payload = {
      officeId: currentSelectedFranchise._id,
    };
    dispatch(accountHandle.updateUserProfile(payload));
    setModelDrawer({ status: false, component: "", title: "" });
    setSelectedData({})
  };

  const showConfirmModal = () => {
    confirm({
      title:
        "Your Existing firm details will be lost and new selected details will be under verification.",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Do you still want to continue?",
      onOk() {
        handleOk();
      },
      onCancel() {},
    });
  };
const handleOfficedetails=()=>{
 let query = {};
  // if(currentTag.current==="FRANCHISE")
  // {
  //   if(!searchText&&franchiseDataLength!==getFranchiseData.length)
  //   {
  //     let query={
  //       officeTypes:"FRANCHISE"
  //     }
  //     dispatch(accountHandle.getAllFranchise(query));
  //   }
  //   setDataSource(getFranchiseData)
  // }
  // else 
  if(currentTag.current==="BROKERAGE")
  {
    if(searchText){
      query={
        officeTypes:"BROKERAGE",
        parentId:selectedData._id,
        search:searchText
      }
    }else{
    query={
      officeTypes:"BROKERAGE",
      parentId:selectedData._id,
      }
    }
    dispatch(accountHandle.getBrokrage(query));
  setDataSource(getBrokrageData)
  }
  else if(currentTag.current==="OFFICE")
  {
    if(searchText){
      query={
        officeTypes:"OFFICE",
        parentId:selectedData._id,
        search:searchText
      }
    }else{
    query={
      officeTypes:"OFFICE",
      parentId:selectedData._id,
      }
    }
    dispatch(accountHandle.getOffice(query));
    
    setDataSource(getOfficeData)
  }
}
  const handleOk = () => {
    let payload = {
      officeId: currentSelectedFranchise._id,
    };
    dispatch(accountHandle.updateUserProfile(payload));
    setModelDrawer({ status: false, component: "", title: "" });
    setSelectedData({})
  };

  useEffect(() => {
    if(searchFirmText?.length <= 0 && showListModal){
      handleOfficedetails()
    }
  },[searchFirmText])

  return (
    <>
      {/* Franchise List Modal */}
      <Modal
        visible={showListModal}
        onOk={() => {}}
        style={{ top: 20, height: "400px" }}
        onCancel={() => {
          setSearchFirmText("")
          setShowListModal(false)
        }}
        footer={false}
      >
    <FranchiseList
          dataSource={dataSource}
          setDataSource={setDataSource}
          selectClient={hnadleFranchiseSelected}
          currentTag={currentTag.current}
          searchFranchiseText={searchText}
          searchFirmText={searchFirmText}
          setSearchFirmText={setSearchFirmText}
        />
      </Modal>
      <Form
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        {/* <Form.Item
          label="Franchise"
          name="franchise"
          rules={[{ required: true, message: "" }]}
        >
          {" "}
          <Row>
            <Col span={20}>
              <div style={{ display: "flex" }}>
                <Input
                  allowClear
                  value={franchiseInput}
                  placeholder="Search a franchise"
                  disabled={franchiseHandle.current}
                  onClick={() => {
                    handleOfficedetails()
                    setShowListModal(true);
                  }}
                  onChange={(e) => {
                    setFranchiseInput(e.target.value);
                    setSearchText(e.target.value);
                    if (e.target.value) {
                      setError({
                        ...error,
                        franchiseStatus: false,
                        message: "",
                      });
                    }
                  }}
                />
              </div>
            </Col>
            {!franchiseHandle.current && (
              <Col span={2} style={{ alignSelf: "center", textAlign: "end" }}>
                <Button
                  onClick={() => {
                    handleOfficedetails()
                    setShowListModal(true);
                  }}
                  style={{ padding: "0px 6px" }}
                >
                  <SearchOutlined style={{ fontSize: "18px" }} />
                </Button>
              </Col>
            )}
          </Row>
          {error.franchiseStatus ? (
            <div style={{ color: "red" }}>{error.message}</div>
          ) : (
            ""
          )}
        </Form.Item>
        {brokrageShow.current && ( */}
          <Form.Item
            label="Brokerage"
            name="brokerageId"
            rules={[{ required: true, message: "" }]}
          >
            <Row>
              <Col span={20}>
                <div style={{ display: "flex" }}>
                  <Input
                    allowClear
                    value={brokerageInput}
                    placeholder="Search a brokerage"
                    disabled={brokerageHandle.current}
                    onClick={() => {
                      setDataSource(getBrokrageData);
                      handleOfficedetails()
                      setSearchFirmText("")
                      setShowListModal(true);
                    }}
                    onChange={(e) => {
                      setbrokerageInput(e.target.value);
                      setSearchText(e.target.value);
                      if (e.target.value) {
                        setError({
                          ...error,
                          brokerageStatus: false,
                          message: "",
                        });
                      }
                    }}
                  />
                </div>
              </Col>
              {!brokerageHandle.current && (
                <Col span={2} style={{ alignSelf: "center", textAlign: "end" }}>
                  <Button
                    onClick={() => {
                      setDataSource(getBrokrageData);
                      handleOfficedetails()
                      setSearchFirmText("")
                      setShowListModal(true);
                    }}
                    style={{ padding: "0px 6px" }}
                  >
                    <SearchOutlined style={{ fontSize: "18px" }} />
                  </Button>
                </Col>
              )}
              {error.brokerageStatus ? (
                <div style={{ color: "red" }}>{error.message}</div>
              ) : (
                ""
              )}
            </Row>
          </Form.Item>
        {/* )} */}
        {officeShow.current && (
          <Form.Item
            label="Office Name"
            name="officeId"
            rules={[{ required: true, message: "" }]}
          >
            <Row>
              <Col span={20} style={{ display: "flex" }}>
                <Input
                  allowClear
                  value={officeInput}
                  placeholder="Search a Office"
                  onClick={() => {
                    setDataSource(getOfficeData)
                    handleOfficedetails()
                    setSearchFirmText("")
                    setShowListModal(true);
                  }}
                  disabled={officeHandle.current}
                  onChange={(e) => {
                    setOfficeInput(e.target.value);
                    setSearchText(e.target.value);
                    if (e.target.value) {
                      setError({
                        ...error,
                        officeStatus: false,
                        message: "",
                      });
                    }
                  }}
                />
              </Col>
              {!officeHandle.current && (
                <Col span={2} style={{ alignSelf: "center", textAlign: "end" }}>
                  <Button
                    onClick={() => {
                      setDataSource(getOfficeData)
                      handleOfficedetails()
                      setSearchFirmText("")
                      setShowListModal(true);
                    }}
                    style={{ padding: "0px 6px" }}
                  >
                    <SearchOutlined style={{ fontSize: "18px" }} />
                  </Button>
                </Col>
              )}
            </Row>
            {error.officeStatus ? (
              <div style={{ color: "red" }}>{error.message}</div>
            ) : (
              ""
            )}
          </Form.Item>
        )}

        <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
          <Button type="primary" onClick={handleSubmit} htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
export { FranchiseDetails };
